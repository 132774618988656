.lowerContainer {
  display: flex;
  flex-direction: row;
  gap: 5vw;
}

.afterPoem {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5vh;
  width: auto;
  height: auto;
}

.cardContainer {
  background-color: rgb(61, 58, 60);
  width: 45vw;
}

.creditsContainer {
  background-color: #013220;
  width: 45vw;
}
