.navbar {
  width: 100%;
  height: auto;
  background-color: rgb(35, 33, 34);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
  box-shadow: 2px 10px 10px #3a3a3a33;
}

.navbar-logo {
  width: 25vh;
  margin-top: 2%;
  margin-bottom: 2%;
  margin-left: 2%;
  padding-left: 0.5%;
}

.writingActivities {
  width: 25vh;
  height: 8vh;
  background-color: #00642c;
  border-radius: 25px;
  border: none;
  font-size: 14pt;
  color: white;
  align-items: center;
  display: flex;
  justify-content: center;
}

.writingActivities:hover {
  background-color: rgb(12, 124, 89, 0.7);
}

.dropdown-toggle {
  width: 25vh;
  height: 8vh;
  background-color: #00642c;
  border-radius: 25px;
  border: none;
  font-size: 14pt;
  color:white;
 
  
}

.dropdown-toggle:hover {
  background-color: rgb(12, 124, 89, 0.7);
}

@media screen and (max-width: 480px) {
  .navbar-logo {
    width: 20vh;
  }

  .dropdown {
    top: 0vh;
    left: 0vw;
  }

  .writingActivities {
    width: 35vw;
    height: 5vh;
    padding-top: 2vh;
    font-size: 10pt;
    left: 60vw;
    top: 1vh;
  }

  .dropdown-toggle {
    width: 35vw;
    height: 5vh;
    font-size: 10pt;
  }

  .ARButton {
    left: 60vw;
    width: 35vw;
    top: 2vh;
  }
}
