body {
    overflow-y: hidden;
  }
  
  .buttonContainer {
    display: flex;
    gap: 1%;
    margin-bottom: 1%;
    justify-content: center;
  }
  
  .ARbuttonWorks {
    border-radius: 25px;
    background-color: #00642c;
    border-color: #00642c;
  }
  
  .ARbuttonTmp {
    border-radius: 25px;
    background-color: #00642c;
    border-color: #00642c;
    margin: 3vh;
  }
  
  .card-title h5 {
    font-size: 35px;
  }
  
  .card-title h6 {
    font-size: 25px;
  }
  
  .card-text h6 {
    font-size: 15px;
  }
  
  .card-body {
    text-align: left;
  }