body,
html {
  font-family: "Open Sans", sans-serif;
  background-color: rgb(35, 33, 34);
  /* color: rgb(219, 206, 210); */
  color: white;
  overflow-x: hidden;
  overflow-y: scroll;
  width: auto;
}

.highlight-blue {
  background: url("../../images/brushStrokes/brush-stroke-blue.png");
  background-repeat: no-repeat;
  background-size: 100% 95%;
  padding: 8px 0;
}

.highlight-orange {
  background: url("../../images/brushStrokes/brush-stroke-orange.png");
  background-repeat: no-repeat;
  background-size: 100% 95%;
  padding: 8px 0;
}

.highlight-black {
  background: url("../../images/brushStrokes/brush-stroke-black.png");
  background-repeat: no-repeat;
  background-size: 100% 95%;
  padding: 8px 0;
}

.highlight-black-full {
  background: url("../../images/brushStrokes/brush-stroke-black-full.png");
  background-repeat: no-repeat;
  background-size: 100% 95%;
  padding: 8px 0;
}

.highlight-red {
  background: url("../../images/brushStrokes/brush-stroke-red.png");
  background-repeat: no-repeat;
  background-size: 100% 95%;
  padding: 8px 0;
}

.highlight-green {
  background: url("../../images/brushStrokes/brush-stroke-green.png");
  background-repeat: no-repeat;
  background-size: 100% 95%;
  padding: 8px 0;
}

.logo-image {
  /*background-color: white;*/
  align-self: center;
  width: 20vw;
  height: auto;
  padding: 3px;
  margin: 25px;
}

.homepage {
  margin-top: 20vh;
  display: flex;
  flex-direction: column;
  margin-left: 1vh;
  gap: 5vh;
}

.top1 {
  height: 60vh;
  background-image: url("../../images/home-page-art.png");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.middle {
  display: flex;
  flex-direction: row;
  gap: 5vw;
  height: 65vh;
  margin-left: 4vw;
  margin-top: 5vh;
}

.bottom {
  display: flex;
  flex-direction: row;
  margin-left: 2vw;
  gap: 2vw;
  margin-top: 5vh;
}

.bottomFooter {
  display: flex;
  flex-direction: row;
}

/* TOP */

.getStartedButton {
  border-radius: 50px;
  background-color: rgb(47, 27, 148);
  width: 21vw;
  height: 75px;
  font-size: 20pt;
  color: rgb(255, 255, 255);
  display: inline;
  top: 35vh;
  border: 4px solid white;
}

.introARButton {
  color: rgb(255, 255, 255);
  display: inline;
  opacity: 1;
}

.introARButton:hover {
  color: rgb(234, 120, 0);
}

.learnMoreButton {
  border-radius: 50px;
  background-color: #00642c;
  border: 4px solid white;
  width: 21vw;
  height: 75px;
  font-size: 20pt;
  color: rgb(255, 255, 255);
  display: inline;
  margin-left: 2vw;
  opacity: 1;
}

.getStartedButton:hover {
  border: 4px solid rgb(234, 120, 0);
  color: rgb(234, 120, 0);
}

.learnMoreButton:hover {
  border: 4px solid rgb(234, 120, 0);
  color: rgb(234, 120, 0);
}

.messageContainer {
  display: flex;
  height: 100%;
}

.partition {
  background-color: rgb(234, 120, 0);
  height: 1vh;
  border-radius: 10px;
}

.headline {
  font-size: 10vh;
  font-weight: 700;
  margin-left: 10vw;
  margin-top: 5vh;
}

.subHeadline {
  position: absolute;
  width: 85vw;
  font-size: 5vh;
  font-weight: 300;
  margin-top: 20vh;
  margin-left: 10vw;
}

.buttons {
  position: absolute;
  margin-top: 50vh;
  left: 0;
  right: 0;

  margin-left: 30vw;
  margin-right: auto;

  width: 50%;

  align-items: center;
}

/* MIDDLE */

.leftMiddle {
  background-color: rgb(35, 33, 34);
  width: 50vw;
  overflow-y: auto;
}

.leftMiddle::-webkit-scrollbar {
  -webkit-appearance: none;
}

.leftMiddle::-webkit-scrollbar:vertical {
  width: 11px;
}

.leftMiddle::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid rgb(58, 57, 57); /* should match background, can't be transparent */
  background-color: rgb(114, 113, 113);
}

.rightMiddle {
  background-color: rgb(35, 33, 34);
  width: 55vw;
}

.sitesContainer {
  position: relative;
  width: 100%;
}

.selectionSubContainer {
  display: flex;
}

.selectionSubContainer:hover {
  background-color: rgb(219, 206, 210, 0.1);
}

.selectionImg {
  width: 25vh;
}

.siteSelection {
  font-family: "Oswald", sans-serif;
  background-color: transparent;
  font-size: 2rem;
  line-height: 4vh;
  font-weight: 500;
  width: 50%;
  border: none;
  color: rgb(219, 206, 210, 0.5);
  overflow-y: auto;
}

.siteSelection:hover {
  background-color: transparent;
  color: rgb(234, 120, 0);
  cursor: pointer;
}

.siteSelection:active {
  background-color: transparent;
  color: rgb(219, 206, 210, 0.5);
  cursor: pointer;
  border: none;
}

.siteSelection:hover i {
  background-color: transparent;
  color: rgb(47, 27, 148);
  cursor: pointer;
  font-size: 17pt;
}

.siteOptions {
  display: flex;
}

.selectionBorder {
  background: radial-gradient(
    closest-side,
    rgb(219, 206, 210, 0.3),
    rgb(35, 33, 34)
  );
  width: 10px;
  position: absolute;
  top: 0;
}

.aRButton {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

/* BOTTOM */

.aboutHeadline {
  font-size: 4vw;
  font-weight: 700;
  margin-bottom: 2vh;
}

.aboutDescription {
  font-size: 13pt;
}

.formInput {
  border-radius: 10px;
  border: none;
  margin-bottom: 1vh;
  padding: 3%;
}

textarea {
  height: 100px;
  width: 100%;
  border-radius: 10px;
  padding-left: 10px;
  font-family: "Open Sans", sans-serif;
}

.formSubmitButton {
  text-align: center;
  border-radius: 50px;
  background-color: rgb(255, 255, 255);
  color: rgb(35, 33, 34);
  width: 100px;
  height: 50px;
  border: none;
  font-size: 15pt;
  display: block;
  margin-top: 10px;
}

.rightBottom {
  background-color: rgb(35, 33, 34);
  margin-left: 10vw;
  margin-top: 15vh;
}

.leftBottom {
  background-color: rgb(35, 33, 34);
  width: 45%;
}

.commentForm {
  display: flex;
  flex-direction: column;
  margin-top: 5vh;
  gap: 1vh;
  width: 35vw;
  background-color: rgb(234, 120, 0, 0.5);
  border-radius: 50px;
  padding: 15%;
}

.commentFormHeader {
  font-size: 25pt;
  color: rgb(255, 255, 255);
}

.formSubmitButtonValue {
  position: relative;
  float: right;
}

.bottomFooter {
  margin-left: 2vw;
}

.organization {
  display: flex;
  flex-direction: column;
  width: 32vw;
  padding: 1%;
  text-align: center;
  
}

.aboutPerson {
  width: 100%;
  margin: 0;
}

.aboutName {
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
}

.aboutRoles {
  font-size: 0.9rem;
  opacity: 0.7;
  margin: 0;
  margin-bottom: 1vh;
}

.organizations {
  display: flex;
  flex-direction: row;
  gap: 2vw;
  width: 80%;
  justify-content: center;
  margin-left: 10vw;
  margin-right: 10vw;
  margin-bottom: 1vh;
}



.about-page-container {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.about-page-header {
  font-size: 40pt;
  margin-bottom: 20px;
  font-weight: 700;
  margin-bottom: 5vh;
  display: flex;
  justify-content: center;
}

.about-header-text {
  color: white;
  font-size: 2.5rem;
  padding: 1vw;
}

@media screen and (max-width: 767px) {
  .organizations {
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 3vh;
  }
  .organization {
    width: 95%;
    margin-top: 0;
    margin-left: 3vw;
  }
}

/* =========================================================================================== */
/* =========================================================================================== */
/* =========================================================================================== */
/* =========================================================================================== */
/* =========================================================================================== */
/* =======================================TABLET========================================= */
/* =========================================================================================== */
/* =========================================================================================== */
/* =========================================================================================== */
/* =========================================================================================== */
/* =========================================================================================== */
/* =========================================================================================== */

@media screen and (max-width: 768px) {
  .messageContainer {
    height: 100%;
    width: 95%;
    top: 7%;
    left: 5%;
    margin-top: 15%;
  }

  .headline {
    font-size: 10vh;
    font-weight: 700;
  }

  .subHeadline {
    font-size: 5vh;
    font-weight: 300;
    width: 100%;
  }

  .bottomFooter {
    margin-top: 5vh;
  }
}

/* =========================================================================================== */
/* =========================================================================================== */
/* =========================================================================================== */
/* =========================================================================================== */
/* =========================================================================================== */
/* =======================================MOBILE========================================= */
/* =========================================================================================== */
/* =========================================================================================== */
/* =========================================================================================== */
/* =========================================================================================== */
/* =========================================================================================== */
/* =========================================================================================== */

@media screen and (max-width: 680px) {
  .homepage {
    gap: 1vh;
    margin-top: 5vh;
    margin-left: 0vh;
    flex-direction: column;
  }

  .top1 {
    height: 80vh;
    background-size: cover;
  }

  .middle {
    flex-direction: column;
    height: 130vh;
    margin-right: 3vw;
  }

  .bottom {
    flex-direction: column;
    gap: 0;
  }

  .leftBottom {
    width: 95%;
    margin-top: 0;
    margin-left: 3vw;
  }

  .rightBottom {
    width: 90%;
    margin-top: 0;
    margin-left: 3vw;
  }

  .headline {
    font-size: 8vh;
    font-weight: 700;
    width: 90%;
    margin-left: 3vw;
  }

  .subHeadline {
    font-size: 4vh;
    width: 90%;
    margin-left: 2vw;
    margin-top: 20vh;
  }

  .buttons {
    margin-top: 50vh;
  }

  .getStartedButton {
    width: 45vw;
    height: 50px;
    font-size: 14pt;
    display: block;
    margin-top: 20px;
  }

  .learnMoreButton {
    width: 45vw;
    height: 50px;
    font-size: 14pt;
    display: block;
    margin-top: 20px;
    margin-left: 0;
  }

  /* MIDDLE */

  .leftMiddle {
    background-color: rgb(35, 33, 34);
    width: 100%;
  }

  .rightMiddle {
    margin-top: 5vh;
    height: 100%;
    width: 120%;
    background-color: rgb(35, 33, 34);
  }

  .bottomFooter {
    margin-top: 5vh;
  }

  /* BOTTOM */

  .aboutHeadline {
    font-size: 40pt;
    margin-bottom: 20px;
    font-weight: 700;
    text-align: left;
  }

  .aboutDescription {
    font-size: 15pt;
    width: 95%;
  }

  .formInput {
    display: block;
    width: 75%;
    height: 40px;
    border-radius: 10px;
    border: none;
  }

  textarea {
    height: 100px;
    width: 100%;
    border-radius: 10px;
    padding-left: 10px;
    font-family: "Open Sans", sans-serif;
  }

  .formSubmitButton {
    text-align: center;
    border-radius: 50px;
    background-color: rgb(255, 255, 255);
    color: rgb(35, 33, 34);
    width: 100px;
    height: 40px;
    border: none;
    font-size: 13pt;
    display: block;
    margin-top: 10px;
  }

  .rightBottom {
    margin-top: 0%;
    left: 0%;
    width: 100%;
    background-color: rgb(35, 33, 34);
  }

  .leftBottom {
    background-color: rgb(35, 33, 34);
    margin-left: 5%;
  }

  .commentForm {
    background-color: rgb(234, 120, 0, 0.5);
    border-radius: 25px;
    width: 90%;
    padding: 10%;
  }

  .commentFormHeader {
    font-size: 20pt;
    position: relative;
    color: rgb(255, 255, 255);
  }
  .organizations {
    flex-direction: column;
    width: 97%;
    align-items: center;
    justify-content: center;
    gap: 3vh;
    margin-left: 0vw;
    margin-right: 0vw;
  }
  .organization {
    width: 95%;
    margin-top: 0;
    margin-left: 3vw;
  }
}
