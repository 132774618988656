.title {
  align-self: center;
  background: url("../../images/brushStrokes/brush-stroke-blue.png");
  background-repeat: no-repeat;
  background-size: 100% 95%;
  padding: 8px 0;
  font-size: 40pt;
  font-weight: 700;
}

.subtitle {
  align-self: center;
  background: url("../../images/brushStrokes/brush-stroke-green.png");
  background-repeat: no-repeat;
  background-size: 100% 95%;
  padding: 12px 0;
  font-size: 30pt;
  font-weight: 600;
}

.activity {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 5%;
}

.caption {
  font-size: 10pt;
  font-weight: 300;
  padding: 8px 0;
  width: 50%;
  opacity: 0.7;
}

.container {
  top: 20vh;
}

@media screen and (max-width: 480px) {
  .pdf {
    width: 80%;
    max-height: 50%;
    align-self: center;
  }

  .caption {
    width: 100%;
  }

  .title {
    font-size: 30pt;
  }
}
