/* Each map can be put into a div with specified CSS */
/* .map {
    position: relative;
    top: 200px;
} */

.marker-button {
    background: none;
    border: none;

}

.mapContainer {
    width: 45vw;
    height: 65vh;
}

.marker-button:hover {
    background-color: transparent;
    background: none;
}

.card-style {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border-radius: 50%;
}

.contentContainer {
    background-color: aliceblue;
    width: auto;
    height: auto;
}

.popupText {
    font-family: 'Open Sans', sans-serif;
}

.image-style {
    height: 150px;
    width: 200px;
    padding-top: 1%;
    padding-left: 1%;
    padding-right: 1%;
    border-radius: 5%;
    
}

.button1 {
    border: none;
    left: 2px;
  }

.button2 {
    border: none;
    left: 6%;
}

@media screen and (max-width: 680px) {
    .mapContainer {
        width: 92vw;
        height: 65vh;
        align-items: center;
    }
}