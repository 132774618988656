body {
  background-color: rgb(35, 33, 34);
}

.details-logo-image {
  background-color: rgb(35, 33, 34);
  width: 300px;
  height: auto;
}

.details .addressLink {
  color: rgb(234, 130, 0);
}

.details .addressLink:hover {
  color: rgb(219, 206, 210);
}

.cardText {
  color: black;
}

.details {
  display: flex;
  flex-direction: column;
  gap: 5vh;
  margin-top: 30vh;
  margin-left: 2vw;
  margin-right: 2vw;
  overflow: hidden;
}

.details .top {
  display: flex;
  flex-direction: row;
  gap: 5vh;
  overflow: hidden;
}

.details .bottom {
  display: flex;
  flex-direction: row;
  top: 70vh;
  gap: 5vh;
  overflow: hidden;
}

.detailsGraphic {
  width: 100%;
}

.details .detailsMapContainer {
  width: 45vw;
}

.graphicContainer {
  width: 50vw;
  height: 50vh;
}

.details .name {
  color: antiquewhite;
  position: fixed;
}

.details .detailsMessageContainer {
  display: flex;
  flex-direction: column;
  width: 100vh;
}

.details .detailsHeadline {
  margin-bottom: 2vh;
  font-size: 30pt;
  font-weight: 700;
}

.details .detailsSubHeadline {
  font-size: 14pt;
  font-weight: 300;
  margin-top: 2vh;
}

.details .detailsPartition {
  background-color: rgb(0, 100, 44);
  width: 3em;
  height: 0.5em;
  border-radius: 10px;
}

.details .instructionsContainer {
  margin-top: 2vh;
}

.details .highlight-blue {
  background: url("../../images/brushStrokes/brush-stroke-blue.png");
  background-repeat: no-repeat;
  background-size: 100% 95%;
  padding: 8px 0;
}

.details .highlight-orange {
  background: url("../../images/brushStrokes/brush-stroke-orange.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 12px 0;
}

.details .siteImages {
  width: 50px;
  border-radius: 25px;
}

.details .detailsButton {
  padding-top: 3vh;
  text-align: center;
}

.details .ARbuttonWorks {
  border-radius: 25px;
  background-color: #00642c;
  border-color: #00642c;
}

.details .ARbuttonTmp {
  border-radius: 25px;
  background-color: #00642c;
  border-color: #00642c;
}

.details .buttonContainer {
  display: flex;
  gap: 1%;
  margin-bottom: 1%;
}

.bottomFooter {
  display: flex;
  flex-direction: row;
}

.details .buttonText {
  text-align: center;
}

.details .lowerContainer {
  display: flex;
  flex-direction: row;
  gap: 5vw;
}

.details .cardContainer {
  background-color: rgb(61, 58, 60);
  width: 45vw;
}

.details .creditsContainer {
  background-color: #013220;
  width: 45vw;
}

.details .photoGallery {
  display: flex;
  flex-direction: column;
  gap: 5vh;
}

.details .galleryCard {
  max-width: 45vw;
  max-height: auto;
}

.details .galleryCar {
  max-width: 45vw;
  max-height: auto;
}

.details .afterPoem {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5vh;
  width: auto;
  height: auto;
}

@media screen and (max-width: 480px) {
  .details {
    margin-top: 15vh;
  }

  .details .cardContainer {
    width: 95vw;
  }

  .details .creditsContainer {
    width: 95vw;
  }

  .details .ARbuttonWorks {
    margin-top: 3vh;
    margin-bottom: 1vh;
    width: 60vw;
    height: 7vh;
  }

  .details .lowerContainer {
    flex-direction: column-reverse;
  }

  .cardContainer {
    width: 100vw;
  }

  .details .ARbuttonTmp {
    margin-bottom: 1vh;
    margin-top: 3vh;
    width: 60vw;
    height: 6vh;
  }

  .details .top {
    flex-direction: column-reverse;
    height: 100%;
  }

  .details .bottom {
    flex-direction: column;
  }

  /*TOP*/

  .details .detailsMessageContainer {
    width: 100%;
  }

  .graphicContainer {
    width: 100%;
    height: 100%;
  }

  .details .outerMapContainer {
    width: 95vw;
    height: 50vh;
    overflow: hidden;
  }

  .details .detailsHeadline {
    font-size: 25pt;
    font-weight: 700;
  }

  .details .detailsSubHeadline {
    font-size: 15pt;
  }
}
